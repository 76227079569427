/* https://github.com/styled-components/styled-components/issues/2205#issuecomment-438844490 */
@font-face {
  font-family: "Nunito Bold";
  src: url(./nunito/Nunito-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "DM Sans Medium";
  src: url(./dm_sans/DMSans-Medium.ttf);
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "DM Sans Regular";
  src: url(./dm_sans/DMSans-Regular.ttf);
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "DM Sans Bold";
  src: url(./dm_sans/DMSans-Bold.ttf);
  font-display: swap;
  font-weight: 400;
}
